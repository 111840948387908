window.idp = {
  env: {
    constants: {
      BASE_URL: 'https://idp.auth-staging.autodesk.com',
      SHOW_KEEP_ME_SIGNED_IN: true,
      HCAPTCHA_SITE_KEY_SIGN_IN: '90e811e6-aec4-4612-a8c2-b7dc4652332e',
      HCAPTCHA_SITE_KEY_EMAIL_VERIFICATION: '9dda6a5e-8d72-4428-81d2-f6f405a80af8',
      HCAPTCHA_SITE_KEY_MFA: '71a42e26-d14e-4c66-b32c-456a807af01e',
      IDSDK_LOGOUT_CLIENT_ID: 'LWDG5s1awA74jveDwbzx6JDNEH0LqNsG8HTq5eii5TVGWFor',
      GOOGLE_ANALYTICS_ID: 'G-R8S09FWK9X',
      ENABLE_OPT_OUT_BANNER: false,
    },
  },
};
